<template>
  <b-card>
    <div v-if="isLoadingInitialContentsContracts" class="p-1 text-center border"> <b-spinner/> <br> Cargando módulo</div>
    <Contracts v-else />
  </b-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import Contracts from '@/modules/fivesClub/components/contracts/Contracts'


export default {
  components:{
    Contracts,
  },
  data(){
    return {
      isLoadingInitialContentsContracts: false,
    }
  },
  async mounted() {
    // if( !this.currencies.length ){
    //   const currencies = await this.fetchCurrencies()  
    //   this.setCurrencies(currencies) //muto state currencies en start 
    // }
    await this.getInitialData()
  },
  computed: {
    ...mapState('start',['currencies']),
  },
  methods: {
    ...mapActions('fivesClubContracts', ['getInitialContentContracts']),
    ...mapActions('start',['fetchCurrencies']),
    ...mapMutations('start',['setCurrencies']),
    async getInitialData(){
      this.isLoadingInitialContentsContracts = true
      const initialContent = await this.getInitialContentContracts({
        retornos: true,
        paymentTypes: true,
        memberships: true,
        statuses: true,
        hotels: true,
        rentalPool: true
      })
      if (initialContent) this.isLoadingInitialContentsContracts = false
      else {
        this.isLoadingInitialContentsContracts = false
        console.log('error en las peticiones de carga del módulo')
      }
    }
  },
};
</script>