<template>
  <div>
    <div class="list-filters mb-1">
      <div class="d-flex justify-content-between mb-1">
        <h4>Filtros</h4>
        <div v-if="contracts.length">
          <b>Total de contratos: </b> <b-badge variant="success" class="mr-1" >  {{contracts.length}} </b-badge>
          <b-button
            type="submit"
            size="sm"
            class="btn fives-club-excel-btn"
            @click="downloadExcelContracts"
            v-can="'fivesclub_contracts_show_contracts_report'"
            v-if="requestContracts && contracts.length && !isDowloadingExcel"> <feather-icon icon="FileTextIcon" /> Excel
          </b-button>
          <b-spinner label="Loading..." v-if="isDowloadingExcel"/>
        </div>
      </div>

      <validation-observer tag="form" v-slot="{ invalid }" >
        <b-form @submit.prevent="handlerFilter">
          <b-row>
            <b-col md="">
              <validation-provider name="resort">
                <b-form-group slot-scope="{ valid, errors }" label="Resort">
                  <b-form-select
                    :state="errors[0] || !valid ? false : null"
                    v-model="searchFilter.resort"
                    :clearable="false"
                    @change="setResortFilter"
                  >
                    <option :value="null">Seleccione un opción</option>
                    <option
                      v-for="h in hotels"
                      :key="h.id"
                      :value="h.id"
                    >
                      {{ h.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider name="número vivienda" rules="">
                <b-form-group slot-scope="{ valid, errors }" label="Vivienda">
                  <b-form-input
                    v-model="searchFilter.numHouse"
                    @keypress="onlyNumber"
                    :state="errors[0] || !valid ? false : null"
                    placeholder="Número de vivienda"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider name="pool de renta">
                <b-form-group slot-scope="{ valid, errors }" label="Pool de Renta">
                  <b-form-select
                    v-model="searchFilter.rentalPool"
                    placeholder="Pool de Renta"
                    :state="errors[0] || !valid ? false : null"
                  >
                    <option :value="null">Seleccione un opción</option>
                    <option
                      v-for="pool in rentalPool"
                      :key="pool.id"
                      :value="pool.id"
                    >
                      {{ pool.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider name="membresía">
                <b-form-group slot-scope="{ valid, errors }" label="Membresía">
                  <b-form-select
                    :state="errors[0] || !valid ? false : null"
                    v-model="searchFilter.membership"
                    :clearable="false"
                  >
                    <option :value="null">Seleccione un opción</option>
                    <option
                      v-for="mmbr in memberships"
                      :key="mmbr.id"
                      :value="mmbr.id"
                    >
                      {{ mmbr.code }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider name="status">
                <b-form-group slot-scope="{ valid, errors }" label="Status">
                  <b-form-select
                    :state="errors[0] || !valid ? false : null"
                    v-model="searchFilter.status"
                    :clearable="false"
                  >
                    <option :value="null">Seleccione un opción</option>
                    <option
                      v-for="status in contractstatus"
                      :key="status.id"
                      :value="status.id"
                    >
                      {{ status.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="">
              <validation-provider name="Correo propietario">
                <b-form-group
                  slot-scope="{ valid, errors }"
                  label="Correo propietario"
                >
                  <b-form-input
                    v-model="searchFilter.email"
                    type="email"
                    :state="errors[0] || !valid ? false : null"
                    placeholder="mail@domain.com"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider name="Nombre o apellido">
                <b-form-group
                  slot-scope="{ valid, errors }"
                  label="Nombre o apellido"
                >
                  <b-form-input
                    v-model="searchFilter.nameLastname"
                    :state="errors[0] || !valid ? false : null"
                    placeholder="Nombre o apellido"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider name="Tipo de pago">
                <b-form-group label="Tipo de pago" slot-scope="{ valid, errors }" >
                  <b-form-select
                    :state="errors[0] || !valid ? false : null"
                    v-model="searchFilter.paymentType"
                  >
                    <option :value="null">Seleccione un opción</option>
                    <option
                      v-for="pay in paymentTypes"
                      :key="pay.id"
                      :value="pay.id"
                    >
                      {{ pay.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider name="Tipo de retorno">
                <b-form-group label="Tipo de retorno" slot-scope="{ valid, errors }" >
                  <b-form-select
                    :state="errors[0] || !valid ? false : null"
                    v-model="searchFilter.returnType"
                  >
                    <option :value="null">Seleccione un opción</option>
                    <option
                      v-for="ret in retornos"
                      :key="ret.id"
                      :value="ret.id"
                    >
                      {{ ret.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <validation-provider name="Duración" rules="">
                <b-form-group slot-scope="{ valid, errors }" label="Duración">
                  <b-form-input
                    v-model="searchFilter.duration"
                    :state="errors[0] || !valid ? false : null"
                    placeholder="Indique los años de duracion"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr class="">
          <b-row>
            <b-col md="8" class="mt-1 ml-1" v-if="queryFilter.length">
              <label>
                <strong>Filtrando por </strong>
                <strong v-for="(filter, index) in queryFilter" :key="filter">
                  {{ queryFilter.length > 1 && index + 1 == queryFilter.length ? ' y ' : ''}}
                  {{ filter }}{{ index + 1 == queryFilter.length ? '.' : index + 1 == queryFilter.length - 1 ? '' : ', '}}</strong>
              </label>
            </b-col>
            <b-col class="m-0">
              <b-button
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="invalid || isLoadingContracts || requireParameters"
                class="float-right ml-1 mb-0"
              >
                Buscar
              </b-button>
              <b-button
                type="button"
                variant="warning"
                :disabled="isLoadingContracts"
                class="float-right ml-1 mb-0"
                @click="resetList"
              >
                <!-- <feather-icon icon="FileTextIcon"/>  -->
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <b-row class="mb-1" v-if="contracts.length">
      <b-col md="2">
        <label>Numero de registros</label>
        <v-select
          v-model="porPagina"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector"
          @input="changePerPage"
        />
      </b-col>
      <b-col md="4">
        <label>Buscar en tabla</label>
        <div class="d-flex">
          <b-form-input
            v-model="filter.queryContracts"
            class="mr-1"
            placeholder="Buscar..."
            type="search"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { filtroContracts } from '@/data/fivesClub'
import { makeParamsFilterContracts } from '@/helpers/fivesClubHelper'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { can } from '@/directives/acl'

export default {
  directives: { Ripple, can },
  mixins: [utils],
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
    isLoadingContracts: {
      type: Boolean,
      required: true
    }
  },
  components: {
    vSelect,
  },

  data() {
    return {
      porPagina: this.perPage,

      Filtrado: null,
      filtro: filtroContracts,
      alterFiltro: null,
      housing: null,
      searchFilter: {
        typeFilter: null,
        rentalPool: null,
        membership: null,
        resort: null,
        duration: null,
        status: null,
        numHouse: null,
        email: null,
        nameLastname: null,
        paymentType: null,
        returnType: null
      },
      isDowloadingExcel: false,
      queryFilter: []
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['loaders', 'memberships', 'paymentTypes', 'retornos', 'rentalPool']),
    ...mapState('fivesClubContracts', ['filter','contractstatus','contracts','requestContracts']),
    ...mapState('start', ['hotels']),
    requireParameters(){
      const {typeFilter, membership, resort, duration, status, numHouse, email, nameLastname, paymentType, returnType, rentalPool} = this.searchFilter
      return (typeFilter == null || typeFilter == '') && (membership == null || membership == '') && (resort == null || resort == '') && (duration == null || duration == '') && (status == null || status == '') && (numHouse == null || numHouse == '') && (email == null || email == '') && (nameLastname == null || nameLastname == "") && (paymentType == null || paymentType == "") && (returnType == null || returnType == "") && (rentalPool == null || rentalPool == "")
    }
  },
  methods: {
    ...mapActions('fivesClubContracts', ['fetchMemberships','fetchExcelContracts']),
    ...mapMutations('fivesClubCatalogs', ['setQueryFilter']),
    ...mapMutations('fivesClubContracts', ['setRequestContracts','setContracts']),

    changePerPage(perPage) {
      this.$emit('change-per-page', perPage)
      this.porPagina = perPage
    },
    handlerFilter() {
      const payload = makeParamsFilterContracts(this.searchFilter)
      // se hace un barrido a todas la propiedades del objecto, si encuentra un string le hace el trim (quitar espacios al inicio/fin del string)
      Object.keys(payload).map(k => payload[k] = typeof payload[k] == 'string' ? payload[k].trim() : payload[k])
      this.queryFilter = this.setQueryFilter()
      this.$emit('look-per-filter', payload)
    },
    setResortFilter() {
      this.searchFilter.numHouse = ''
    },
    async downloadExcelContracts(){
      this.isDowloadingExcel = true
      const payload = {
        ...this.requestContracts,
        DownExcel: true
      }
      await this.fetchExcelContracts(payload)
      this.isDowloadingExcel = false
    },
    resetList(){
      this.setRequestContracts(null)
      this.setContracts([])
      this.searchFilter = {
        typeFilter: null,
        membership: null,
        resort: null,
        duration: null,
        status: null,
        numHouse: null,
        email: null,
        nameLastname: null,
        paymentType: null,
        returnType: null,
        rentalPool: null
      },
      this.queryFilter = []
      this.$emit('reset-filter')
    },
    setQueryFilter(){
      const {duration, email, membership, nameLastname, numHouse, resort, status, paymentType, returnType, rentalPool} = this.searchFilter
      const filterList = []
      if (resort) filterList.push(`Resort: ${this.hotels?.find(hotel => hotel.id == resort)?.name}`)
      if (membership) filterList.push(`Membresía: ${this.memberships?.find(members => members.id == membership)?.code}`)
      if (status) filterList.push(`Status: ${this.contractstatus?.find(statuscontract => statuscontract.id == status)?.name}`)
      if (paymentType) filterList.push(`Tipo de pago: ${this.paymentTypes?.find(pagos => pagos.id == paymentType)?.name}`)
      if (returnType) filterList.push(`Tipo de retorno: ${this.retornos?.find(retornos => retornos.id == returnType)?.name}`)
      if (rentalPool) filterList.push(`Pool de Renta: ${this.rentalPool?.find(pool => pool.id == rentalPool)?.name}`)
      if (duration) filterList.push(`Duración: ${duration} años`)
      if (numHouse) filterList.push(`Número de Vivienda: ${numHouse}`)
      if (nameLastname) filterList.push(`Nombre o Apellido: ${nameLastname}`)
      if (email) filterList.push(`Email: ${email}`)
      return filterList
    }
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.isCentered {
  align-content: center;
  align-items: center;
}
.isSpinner {
  padding: 2rem;
}
/* .filters-contracts {
  padding-bottom: 2rem;
} */
.paddler {
  padding-left: 1rem;
}

/* .filters-contracts {
  border: solid 1px #b8c2cc;
  padding: 12px 12px 13px 12px !important;
  margin: 0 0 16px 0;
  background-color: #f3f2f7;
}
.dark-layout .filters-contracts {
  background-color: #3d5675;
} */
.margin-half-custom{
  margin: 0.5rem !important;
}
</style>